
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.feedback-form {
  &__question {
    &-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
    }
    &-vp {
      flex: 1;
    }
    &-action {
      cursor: pointer;
    }
    &-checkbox {
      margin-top: 8px;
    }
  }
  &__datepicker {
    min-width: 230px;
  }
  &__row {
    margin: 0 0 20px 0;
    &--flex {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
    }
    &--min-height {
      min-height: 48px;
    }
  }
  &__subtitle {
    margin: 0 0 10px 0;
  }
  &__later-days {
    width: 300px;
    margin-top: -$base-gutter * 1.3;
  }
}
